var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{attrs:{"column":""}},[_c('vbt-search',{attrs:{"items":_vm.searchFieldsConfig},on:{"reset":_vm.onResetSearch,"search":_vm.onSearch},model:{value:(_vm.searchData),callback:function ($$v) {_vm.searchData=$$v},expression:"searchData"}}),_c('vbt-content-box',{attrs:{"loading":_vm.loading,"loading-error":_vm.loadingError,"no-content":_vm.noContent,"go-back-btn":_vm.goBack,"title":"Information"}},[_c('vbt-table',{attrs:{"headers":_vm.headers,"items":_vm.inputMessages},scopedSlots:_vm._u([{key:"created",fn:function(ref){
var _dateForFormatting = ref.item.created;
return [_vm._v(" "+_vm._s(_vm.formatDisplayDate(_dateForFormatting))+" ")]}},{key:"firstAttempt",fn:function(ref){
var _dateForFormatting = ref.item.firstAttempt;
return [_vm._v(" "+_vm._s(_vm.formatDisplayDate(_dateForFormatting))+" ")]}},{key:"nextAttempt",fn:function(ref){
var _dateForFormatting = ref.item.nextAttempt;
return [_vm._v(" "+_vm._s(_vm.formatDisplayDate(_dateForFormatting))+" ")]}},{key:"state",fn:function(ref){
var state = ref.item.state;
return [_c('dsb-entity-badge',{attrs:{"entity":state}})]}}])}),_c('vbt-pagination',{attrs:{"items-count":_vm.pagedItemsCount},model:{value:(_vm.pageNum),callback:function ($$v) {_vm.pageNum=$$v},expression:"pageNum"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }