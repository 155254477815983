<template>
  <v-layout column>
    <vbt-search
      v-model="searchData"
      :items="searchFieldsConfig"
      @reset="onResetSearch"
      @search="onSearch"
    />

    <vbt-content-box
      :loading="loading"
      :loading-error="loadingError"
      :no-content="noContent"
      :go-back-btn="goBack"
      title="Information"
    >
      <vbt-table
        :headers="headers"
        :items="inputMessages"
      >
        <template #created="{ item: { created: _dateForFormatting } }">
          {{ formatDisplayDate(_dateForFormatting) }}
        </template>

        <template #firstAttempt="{ item: { firstAttempt: _dateForFormatting } }">
          {{ formatDisplayDate(_dateForFormatting) }}
        </template>

        <template #nextAttempt="{ item: { nextAttempt: _dateForFormatting } }">
          {{ formatDisplayDate(_dateForFormatting) }}
        </template>

        <template #state="{ item: { state } }">
          <dsb-entity-badge :entity="state" />
        </template>
      </vbt-table>

      <vbt-pagination
        v-model="pageNum"
        :items-count="pagedItemsCount"
      />
    </vbt-content-box>
  </v-layout>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';

import { useDataLoading, usePagination, useSearch } from '@mixins/factories';

import { formatDisplayDate } from '@helpers';

import { AppLocationsNamespace, EnumsNamespace } from '@store/types';

import { namespace as InputMessagesNamespace, ActionTypes as InputMessagesActionTypes } from '../store.types';

const dataLoadingOptions = {
  getterName: InputMessagesActionTypes.GET_INPUT_MESSAGES,
  contentName: 'inputMessages',
};
const paginationOptions = { contentName: 'inputMessages' };
const searchOptions = {
  initialSearchDataFields: [
    'providerOrder', 'orderItemId', 'type', 'state',
  ],
};

const headers = Object.freeze([
  { text: 'ID', value: 'id' },
  { text: 'Provider Order', value: 'providerOrder' },
  { text: 'Order Item ID', value: 'orderItemId' },
  { text: 'Type', value: 'messageType' },
  { text: 'Created Date', value: 'created' },
  { text: 'First Attempt', value: 'firstAttempt' },
  { text: 'Next Attempt', value: 'nextAttempt' },
  { text: 'Details', value: 'details' },
  { text: 'State', value: 'state' },
]);

export default {
  name: 'DsbInputMessages',

  mixins: [
    useDataLoading(dataLoadingOptions),
    usePagination(paginationOptions),
    useSearch(searchOptions),
  ],

  computed: {
    ...mapState(AppLocationsNamespace, ['suffix']),
    ...mapState(InputMessagesNamespace, ['inputMessages']),

    ...mapGetters(EnumsNamespace, ['inputMessagesTypes', 'inputMessagesStates']),

    searchFieldsConfig() {
      return [
        {
          value: 'providerOrder',
          label: 'Provider Order',
          type: 'text',
          flex: 'sm3',
        },
        {
          value: 'orderItemId',
          label: 'Order Item ID',
          type: 'text',
          flex: 'sm3',
        },
        {
          value: 'type',
          label: 'Type',
          items: this.inputMessagesTypes,
          type: 'select',
          flex: 'sm3',
        },
        {
          value: 'state',
          label: 'State',
          items: this.inputMessagesStates,
          type: 'select',
          flex: 'sm3',
        },
      ];
    },

    headers() {
      return this.inputMessages.some(({ details }) => details)
        ? headers.map((h) => (h.value === 'details' ? { ...h, width: '250px' } : h))
        : headers;
    },
  },

  methods: {
    ...mapActions(InputMessagesNamespace, [InputMessagesActionTypes.GET_INPUT_MESSAGES]),

    formatDisplayDate,
  },
};
</script>
